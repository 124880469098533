import * as serializers from '@/constants/serializer';
import { ENUM } from '@/constants/viewTypes';
import { clone } from '@/utils/clone';
import * as schemaItemTypes from './schemaItemTypes';
import * as onUpdateEffects from './onUpdateEffects';

const dependentProperties = {
  BUILDINGS: 'buildings',
  PROJECTS: 'projects',
  UNITS: 'units',
  ROOMS: 'rooms',
  ROLES: 'roles',
  CLIENTS: 'clients',
  PERFORMERS: 'performers',
  SEARCH_BAR: 'searchBar',
  OVERDUE_TASKS: 'overdueTasks',
  MY_TASKS: 'myTasks',
};

export const schema = [
  {
    type: schemaItemTypes.FLEX_ROW,
    children: [
      {
        label: 'tasks.only_overdue_tasks',
        type: schemaItemTypes.ENUM,
        viewType: ENUM.switch,
        prop: dependentProperties.OVERDUE_TASKS,
        defaultValue: false,
      },
      {
        label: 'tasks.only_my_task',
        type: schemaItemTypes.ENUM,
        viewType: ENUM.switch,
        prop: dependentProperties.MY_TASKS,
        defaultValue: false,
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.MANY_PROJECTS,
        label: 'label.projects',
        prop: dependentProperties.PROJECTS,
        payload: [
          {
            param: 'buildings',
            from: [dependentProperties.BUILDINGS],
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
            onlyEffect: true,
          },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.RESET,
            to: dependentProperties.BUILDINGS,
          },
        ],
      },
      {
        type: schemaItemTypes.MANY_BUILDINGS,
        label: 'label.buildings',
        prop: dependentProperties.BUILDINGS,
        payload: [
          {
            param: 'projects',
            from: [dependentProperties.PROJECTS],
            cache: true,
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
          },
          {
            param: 'units',
            from: [dependentProperties.UNITS],
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
            onlyEffect: true,
          },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.AUTOCOMPLETE_MANY,
            to: dependentProperties.PROJECTS,
          },
          {
            type: onUpdateEffects.RESET,
            to: dependentProperties.UNITS,
          },
        ],
      },
      {
        type: schemaItemTypes.MANY_UNITS,
        label: 'label.units',
        prop: dependentProperties.UNITS,
        payload: [
          {
            param: 'projects',
            from: [dependentProperties.PROJECTS],
            cache: true,
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
          },
          {
            param: 'buildings',
            from: [dependentProperties.BUILDINGS],
            cache: true,
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
          },
          {
            param: 'rooms',
            from: [dependentProperties.ROOMS],
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
            onlyEffect: true,
          },
          {
            param: 'clients',
            from: [dependentProperties.CLIENTS],
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
            onlyEffect: true,
          },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.AUTOCOMPLETE_MANY,
            to: dependentProperties.BUILDINGS,
          },
          {
            type: onUpdateEffects.RESET,
            to: dependentProperties.CLIENTS,
          },
        ],
      },
      {
        type: schemaItemTypes.MANY_ROOMS,
        label: 'label.rooms',
        prop: dependentProperties.ROOMS,
        access: ['rooms'],
        payload: [
          {
            param: 'projects',
            from: [dependentProperties.PROJECTS],
            cache: true,
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
          },
          {
            param: 'buildings',
            from: [dependentProperties.BUILDINGS],
            cache: true,
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
          },
          {
            param: 'units',
            from: [dependentProperties.UNITS],
            cache: true,
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
          },
          {
            param: 'clients',
            from: [dependentProperties.CLIENTS],
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
            onlyEffect: true,
          },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.AUTOCOMPLETE_MANY,
            to: dependentProperties.UNITS,
          },
          {
            type: onUpdateEffects.RESET,
            to: dependentProperties.CLIENTS,
          },
        ],
      },
      {
        type: schemaItemTypes.MANY_CLIENTS,
        label: 'label.clients',
        prop: dependentProperties.CLIENTS,
        payload: [
          {
            param: 'projects',
            from: [dependentProperties.PROJECTS],
            cache: true,
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
          },
          {
            param: 'buildings',
            from: [dependentProperties.BUILDINGS],
            cache: true,
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
          },
          {
            param: 'units',
            from: [dependentProperties.UNITS],
            cache: true,
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
          },
          {
            param: 'rooms',
            from: [dependentProperties.ROOMS],
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
            cache: true,
          },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.AUTOCOMPLETE_MANY,
            to: dependentProperties.UNITS,
          },
          // {
          //   type: onUpdateEffects.AUTOCOMPLETE_MANY,
          //   to: dependentProperties.ROOMS,
          // },
        ],
      },
      {
        type: schemaItemTypes.MANY_ROLES,
        label: 'label.roles',
        prop: dependentProperties.ROLES,
      },
    ],
  },
];

const schemaRules = [
  {
    type: schemaItemTypes.FLEX_ROW,
    children: [
      {
        label: 'tasks.only_my_task',
        type: schemaItemTypes.ENUM,
        viewType: ENUM.switch,
        prop: dependentProperties.MY_TASKS,
        defaultValue: false,
      },
    ],
  },
];

const schemaForDesktop = clone(schema);

schemaForDesktop[0].children.unshift({
  type: schemaItemTypes.SEARCH_BAR,
  prop: dependentProperties.SEARCH_BAR,
  label: 'tasks.search',
  defaultValue: '',
});

const schemaForDesktopRules = [
  {
    type: schemaItemTypes.FLEX_ROW,
    children: [
      {
        type: schemaItemTypes.SEARCH_BAR,
        prop: dependentProperties.SEARCH_BAR,
        label: 'tasks.search',
        defaultValue: '',
      },
      {
        label: 'tasks.only_my_task',
        type: schemaItemTypes.ENUM,
        viewType: ENUM.switch,
        prop: dependentProperties.MY_TASKS,
        defaultValue: false,
      },
    ],
  },
];

export { schemaForDesktop };
export { schemaForDesktopRules };
export { schemaRules };
