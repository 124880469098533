<template>
  <base-missing-data :icon="$options.IconMissingDataTasks" class="tasks-missing-data" :message="message" />
</template>

<script>
import BaseMissingData from '@/components/BaseMissingData.vue';

import * as taskStatuses from '@/constants/taskStatuses';
import IconMissingDataTasks from '@/components/MissingDataIcons/MissingDataTask.vue';

export default {
  name: 'TasksMissingData',

  components: { BaseMissingData },

  props: {
    status: { type: String, default: 'pending' },
  },
  computed: {
    isPending() {
      return this.status === taskStatuses.PENDING;
    },
    isInProgress() {
      return this.status === taskStatuses.IN_PROGRESS;
    },
    isRecurrence() {
      return this.status === taskStatuses.RECURRENCE;
    },
    message() {
      if (this.isPending) {
        return this.$t('tasks.pending_missing_message');
      }

      if (this.isInProgress) {
        return this.$t('tasks.progress_missing_message');
      }

      if (this.isRecurrence) {
        return this.$t('tasks.recurring_missing_message');
      }

      return this.$t('tasks.canceled_and_completed_missing_message');
    },
  },
  IconMissingDataTasks,
};
</script>
