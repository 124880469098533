<template>
  <v-row class="base-row-field-table">
    <v-col class="base-row-field-table__label" cols="4">
      {{ label }}
    </v-col>
    <v-col class="base-row-field-table__text">
      {{ text }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'BaseRowFieldTable',
  props: {
    label: { type: String, default: '' },
    text: { type: String, required: true },
  },
};
</script>

<style lang="scss">
.base-row-field-table {
  &__label {
    font-weight: $--font-weight-normal;
    font-size: 12px;
    line-height: 20px;
    color: $--grey-color-0;
  }
  &__text {
    font-weight: $--font-weight-normal;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
